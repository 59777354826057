import styles from './App.module.scss';
import {useApp} from "./AppProvider";
import Quiz from "./pages/quiz";
import Landing from "./pages/landing";
import Dashboard from "./pages/dashboard";

function App() {
    let app = useApp();

    if (!app.data)
        return null;

    let content;
    if (app.data.user && new Date(app.data.quiz.starts_at).getTime() <= new Date().getTime())
        content = <div className={"App__container"}>
            {app.gameOpen === false ? <Dashboard questions={app.data.questions}/> :
                <Quiz data={app.data} defaultQuestion={app.gameOpen !== true ? app.gameOpen : undefined}/>}
        </div>;
    else
        content = <>
            <Landing/>
        </>;

    return <div className={styles.App}>
        {content}
    </div>;
}

export default App;
